import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import Research from '../pages/ResearchPage.vue';
import AboutPage from '../pages/AboutPage.vue';
import SetPassword from '../pages/SetPassword.vue';
// import APIs from '../pages/APIsPage.vue';
// import WebPlatformTutorial from '../pages/WebPlatformTutorial.vue';

const routes = [
   {
    path: "/:pathMatch(.*)*",
     redirect: { name: 'Home' }
  },
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/technology',
    name: 'Technology',
    component: HomePage
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: HomePage
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: HomePage
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: HomePage
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/research',
    name: 'ResearchPage',
    component: Research
  },
  /* {
    path: '/tutorial',
    name: 'APIsPage',
    component: APIs
  },
  {
    path: '/web-platform-tutorial',
    name: 'WebPlatformTutorialPage',
    component: WebPlatformTutorial
  }, */
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/set-password/:id/:token/',
    name: 'SetPassword',
    component: SetPassword
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

