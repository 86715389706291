<template>
  <div class="introduction">
    <vue-particles
      style="height: 100vh; z-index: 1"
      :params="particleParams"
      :particleOpacity="0.2"
    ></vue-particles>
    <div class="introduction__wrapper container">
      <h1 class="introduction__headline">
        <span class="introduction__headline introduction--color-change"
          >Dynamic Flow Network
        </span>
      </h1>
      <h2 class="introduction__subline">
        The World's most accurate AI Forecast just got better. Be the first to
        try the version DFN-3.
      </h2>

      <!-- <div class="introduction__icons">
        <div class="introduction__icon">
          <img
            class="introduction__icon-image"
            src="@/assets/introduction/devices-icon.svg"
            alt="no devices"
          />
          <h6 class="introduction__icon-text">No devices</h6>
        </div>
        <div class="introduction__icon">
          <img
            class="introduction__icon-image"
            src="@/assets/introduction/sensors-icon.svg"
            alt="no sensors"
          />
          <h6 class="introduction__icon-text">No sensors</h6>
        </div>
        <div class="introduction__icon">
          <img
            class="introduction__icon-image"
            src="@/assets/introduction/cameras-icon.svg"
            alt="no cameras"
          />
          <h6 class="introduction__icon-text">No cameras</h6>
        </div>
      </div> -->
    </div>
    <form class="join__form" @submit.prevent="submitJoinWaitlist">
      <label class="join__label hidden" for="email_request">Email:</label>
      <input
        class="join__input"
        :style="{ width: isMobile ? '90%' : '30%' }"
        type="email"
        v-model="email"
        placeholder="Email"
      />
      <button class="join__button" id="join_btn" type="submit">
        Join waitlist
      </button>
    </form>
    <div style="display: flex; justify-content: center; margin-top: 50px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          z-index: 20;
          position: relative;
        "
        :style="{
          width: isMobile ? '40%' : '10%',
        }"
      >
        <a
          target="blank"
          class="contact__link contact--color-change"
          href="https://www.facebook.com/assetfloow/"
          ><img
            src="@/assets/icon-facebook-hover.svg"
            alt="email icon color"
            class="contact-form__icon"
          />
        </a>
        <a
          target="blank"
          class="contact__link contact--color-change"
          href="https://www.linkedin.com/company/assetfloow/"
          ><img
            src="@/assets/icon-linkedin-hover.svg"
            alt="email icon color"
            class="contact-form__icon"
          />
        </a>
        <a
          target="blank"
          class="contact__link contact--color-change"
          href="https://www.instagram.com/assetfloow/"
          ><img
            src="@/assets/icon-instagram-hover.svg"
            alt="email icon color"
            class="contact-form__icon"
          />
        </a>
      </div>
    </div>
    <div class="slider-wrapper">
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
              src="@/assets/clients/bayern.png"
              class="slide__img"
              alt="Bayern LB"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/rewe.png"
              class="slide__img"
              alt="Rewe"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/tesco.png"
              class="slide__img"
              alt="Tesco"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/eroski.png"
              class="slide__img"
              alt="Eroski"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/delta.png"
              class="slide__img"
              alt="Delta"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/hirota.png"
              class="slide__img"
              alt="Hirota"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/pompeian.png"
              class="slide__img"
              alt="Pompeian"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/secil.png"
              class="slide__img"
              alt="Secil"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/sodexo.png"
              class="slide__img"
              alt="Sodexo"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/wincanton.png"
              class="slide__img"
              alt="Wincanton"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/bayern.png"
              class="slide__img"
              alt="Bayern LB"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/rewe.png"
              class="slide__img"
              alt="Rewe"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/tesco.png"
              class="slide__img"
              alt="Tesco"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/eroski.png"
              class="slide__img"
              alt="Eroski"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/delta.png"
              class="slide__img"
              alt="Delta"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/hirota.png"
              class="slide__img"
              alt="Hirota"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/pompeian.png"
              class="slide__img"
              alt="Pompeian"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/secil.png"
              class="slide__img"
              alt="Secil"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/sodexo.png"
              class="slide__img"
              alt="Sodexo"
            />
          </div>
          <div class="slide">
            <img
              src="@/assets/clients/wincanton.png"
              class="slide__img"
              alt="Wincanton"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="snackbar"></div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      email: null,
      showModal: false,
      particleParams: {
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              mode: "push",
            },
          },
        },
        modes: {
          repulse: {
            distance: 200,
          },
        },
      },
    };
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll);
  //   this.handleScroll();
  // },
  // unmounted() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    showSnackbarSuccess(text) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✔ " + text;
      snackbar.style.background = "#7da53a";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
    showSnackbarError(error) {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "✗ " + error;
      snackbar.style.background = "#962929";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
    requiredFields() {
      const snackbar = document.querySelector("#snackbar");
      snackbar.className = "show";
      snackbar.innerHTML = "Please insert the email.";
      snackbar.style.left = this.isMobile ? "4%" : "38%";
      snackbar.style.background = "#e5ab46";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
    clearFields() {
      this.email = "";
    },
    submitJoinWaitlist() {
      if (!this.email) {
        this.requiredFields();
        return;
      }

      let url = "https://api-brands.assetfloow.com/api/v1/join-waitlist/";

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: url,
        data: {
          email: this.email,
        },
      })
        .then(function (response) {
          if (response.status != 200 && response.status != 201) {
            this.showSnackbarError(
              "An error has occurred. please try again later."
            );
          } else {
            return response;
          }
        })
        .then(() => {
          this.clearFields();
          this.showSnackbarSuccess("You successfully joined the waitlist.");
        })
        .catch(() => {
          this.showSnackbarError(
            "An error has occurred. please try again later."
          );
        });
    },
    openModal() {
      this.showModal = true;
    },
    // handleScroll() {
    //   const iconsContainer = document.querySelector(".introduction__icons");
    //   const triggerPoint = window.innerHeight / 3.5;

    //   if (iconsContainer) {
    //     const containerPosition = iconsContainer.getBoundingClientRect().top;

    //     if (containerPosition < triggerPoint) {
    //       iconsContainer.classList.add("visible");
    //     } else {
    //       iconsContainer.classList.remove("visible");
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

#snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 350px;
  /* Set a default minimum width */

  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 8px;
  /* Rounded borders */
  padding: 0.5rem 1.5rem;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 22;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.introduction {
  height: 101vh;
  background: url("@/assets/introduction/background-hero.png");
  background-size: cover;
  font-family: "aeonik";

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    text-align: center;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    max-width: 800px;
    user-select: none;
  }

  &__headline {
    color: white;
    font-size: 48px;

    @include responsive($breakpoint) {
      font-size: 58px;
    }
  }

  &__subline {
    font-size: 24px;
    max-width: 530px;
    margin-top: 20px;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
    max-width: 530px;
    width: 100%;

    @include responsive($breakpoint) {
      justify-content: space-between;
    }
  }

  &__icon-image {
    width: 40px;
    margin-bottom: 0.5rem;

    @include responsive($breakpoint) {
      width: 50px;
      margin-bottom: 1rem;
    }
  }

  &__icon-text {
    font-size: 16px;

    @include responsive($breakpoint) {
      font-size: 22px;
    }
  }

  &--color-change {
    @extend %colorchange;
  }
}

// .modal {
//   position: fixed;
//   z-index: 1000;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   background-color: rgba(0, 0, 0, 0.6);
// }

// .modal-content {
//   background-color: #fefefe;
//   margin: auto;
//   padding: 2rem;
//   border: 1px solid #888;
//   border-radius: 1rem;
//   width: 80vw;
//   height: 80vh;
//   overflow: auto;

//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// .close-button {
//   position: absolute;
//   right: 10px;
//   top: 5px;
//   color: #aaa;
//   font-size: 17px;

//   &:hover,
//   &:focus {
//     color: #838383;
//     text-decoration: none;
//     cursor: pointer;
//   }
// }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(60px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.introduction__icons {
  transform: translateY(60px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

.join {
  &__form {
    margin-top: -300px;
    display: flex;
    justify-content: center;
  }

  &__input[type="email"] {
    position: relative;
    padding: 1rem;
    border-radius: 2rem;
    border: none;
    margin-bottom: 1rem;
    z-index: 20;
  }

  &__button {
    @include button(fit-content, fit-content);

    margin-left: -130px;
    margin-bottom: 20px;
    margin-top: 4px;
    position: relative;
    background: $button-background-2;
    border: none;
    box-shadow: none;
    color: $white-color;
    z-index: 20;
  }
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.slider {
  width: 90%;
  overflow: hidden;
  position: relative;
  margin-top: 2rem;
}

.slide-track {
  display: flex;
  width: calc(140px * 10 * 2); /* 10 slides, cada um de 150px, duplicados */
  animation: scroll 30s linear infinite;
}

.slide {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.slide img {
  width: 140px;
  height: 64px;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.5;
}

/* Animação contínua */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-140px * 10)); /* Move a largura de 10 slides */
  }
}
</style>
