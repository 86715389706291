import { createApp } from 'vue';
import App from './App.vue';
import './styles/styles.scss'
import router from './router';
import AOS from 'aos';
import VueParticles from 'vue-particles'  
import 'aos/dist/aos.css';

import VueCookies from "vue-cookies";
import '@mdi/font/css/materialdesignicons.min.css';

const app = createApp(App);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faChevronDown,faChevronUp)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(VueCookies);
app.use(VueParticles);

app.mount('#app');

AOS.init();
