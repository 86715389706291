<template>
  <div class="forgot" id="recovery" v-if="isOpen">
    <div class="forgot__wrapper">
      <div class="forgot__content container">
        <span class="forgot__close-btn" @click="closeModal">&times;</span>
        <h2 style="margin-bottom: 10px">Forgot your password?</h2>
        <p style="font-size: 14px; margin-bottom: 20px">
          Please enter the email you use to sign in and you will receive
          instructions to reset your password.
        </p>
        <form class="forgot__form" @submit.prevent="submitForm">
          <label class="forgot__label hidden" for="email"
            >Your email address:</label
          >

          <input
            class="forgot__input"
            type="email"
            v-model="email"
            style="margin-top: 20px"
            placeholder="Your email address"
          />
          <br />
          <button
            class="forgot__button"
            style="margin-top: 20px"
            id="submit_btn"
            type="submit"
          >
            Send
          </button>
          <span class="span" @click="closeModal">Back to sign in</span>
        </form>
      </div>
      <div id="snackbar1"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      email: null,
      password: null,
      picked: null,
      isMobile: window.innerWidth <= 768,
    };
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    closeModal() {
      this.email = "";
      (this.picked = null), this.$emit("close");
    },
    clearFields() {
      this.email = "";
      this.picked = null;
    },
    submitForm() {
      if (!this.email || !this.picked) {
        this.requiredFields();
        return;
      }

      let url = "https://api-brands.assetfloow.com/api/v1/auth/password/reset/";

      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: url,
        data: {
          email: this.email,
        },
      })
        .then(function (response) {
          if (response.status != 200) {
            this.showSnackbarError("The email doesn't match any account.");
          } else {
            return response;
          }
        })
        .then(() => {
          this.clearFields();
          this.showSnackbarSuccess("Reset sent successfully.");
        })
        .catch(() => {
          this.showSnackbarError("The email doesn't match any account.");
        });
    },
    showSnackbarSuccess(text) {
      const snackbar = document.querySelector("#snackbar1");
      snackbar.className = "show";
      snackbar.innerHTML = "✔ " + text;
      snackbar.style.background = "#7da53a";
      snackbar.style.left = this.isMobile ? "16%" : "72.5%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    showSnackbarError(error) {
      const snackbar = document.querySelector("#snackbar1");
      snackbar.className = "show";
      snackbar.innerHTML = "✗ " + error;
      snackbar.style.background = "#962929";
      snackbar.style.left = this.isMobile ? "16%" : "72.5%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
        snackbar.style.left = "40%";
      }, 3000);
    },
    requiredFields() {
      const snackbar = document.querySelector("#snackbar1");
      snackbar.className = "show";
      snackbar.innerHTML = "！Please insert all fields.";
      snackbar.style.background = "#e5ab46";
      snackbar.style.left = this.isMobile ? "16%" : "72%";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

.span {
  border: none;
  color: #00204d;
  background-color: inherit;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}

#snackbar1 {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 250px;
  /* Set a default minimum width */

  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 8px;
  /* Rounded borders */
  padding: 0.5rem 1.5rem;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 40%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar1.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.forgot {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 42;
  top: 0;
  left: 0;

  &__wrapper {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgb(246, 249, 252);
    background: linear-gradient(
      180deg,
      rgba(246, 249, 252, 1) 0%,
      rgba(251, 242, 232, 1) 100%
    );

    @include responsive($breakpoint) {
      right: 0;
      width: 480px;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }
  }

  &__content {
    margin: 10% auto;
    padding-top: 20px;
    width: 100%;

    @include responsive($breakpoint) {
      max-width: 400px;
    }
  }

  &__close-btn,
  .forgot-forgot__close-btn {
    font-size: 50px;
    position: absolute;
    right: 1rem;
    top: 0.5rem;

    &:hover,
    &:focus {
      color: lighten(black, 45%);
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__input[type="text"],
  &__input[type="email"],
  &__input[type="password"] {
    width: 100%;
    padding: 1rem;
    border-radius: 2rem;
    border: none;
    margin-bottom: 1rem;
  }

  &__button {
    @include button;
  }
}

::placeholder {
  font-family: "aeoniklight";
  color: #abb3bf;
  font-size: 16px;
}
</style>
